'use client';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-datatable/styles.layer.css';
import '@mantine/dropzone/styles.css';
import 'mantine-react-table/styles.css';

import { createTheme, MantineColorsTuple, MantineProvider as MantineProviderInner } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { useLocale } from 'next-intl';
import { PropsWithChildren } from 'react';

export const PRIMARY_COLOR_TUPLE: MantineColorsTuple = [
    '#e3f5ff',
    '#cce7ff',
    '#9acbff',
    '#64aeff',
    '#3996fe',
    '#1e86fe',
    '#097fff',
    '#006de4',
    '#0060cd',
    '#0053b6'
];

export const PRIMARY_COLOR = PRIMARY_COLOR_TUPLE[6];
export const PRIMARY_FONT_FAMILY = 'var(--inter-font)';

export const theme = createTheme({
    primaryColor: 'primary',
    fontFamily: PRIMARY_FONT_FAMILY,
    colors: {
        primary: PRIMARY_COLOR_TUPLE
    }
});

export default function MantineProvider (props: PropsWithChildren) {
    const locale = useLocale();

    return (
        <MantineProviderInner
            theme={theme}
            forceColorScheme="light"
        >
            <DatesProvider settings={{ locale }}>
                {props.children}
            </DatesProvider>
        </MantineProviderInner>
    );
}