import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/styles/fonts/index.ts\",\"import\":\"Inter\",\"arguments\":[{\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--inter-font\",\"display\":\"swap\",\"adjustFontFallback\":false}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/src/app/providers/mantine/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/src/app/providers/motion/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/src/app/providers/next-intl/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/src/app/providers/tanstack-query/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/src/app/shared-components/auth-loader/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/src/app/shared-components/base-toast/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/src/app/shared-components/top-loader/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/builds/Zk6u1WnuC/0/dteam.dev/digital-library-frontend/src/app/styles/index.scss");
